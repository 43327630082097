import { CommonModule } from '@angular/common';
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withFetch,
  withInterceptors,
  withInterceptorsFromDi,
} from '@angular/common/http';
import { APP_ID, APP_INITIALIZER, ApplicationConfig, ErrorHandler, importProvidersFrom } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DIALOG_DEFAULT_OPTIONS, MatDialogConfig, MatDialogModule } from '@angular/material/dialog';
import { BrowserModule, provideClientHydration, withEventReplay } from '@angular/platform-browser';
import { provideAnimationsAsync } from '@angular/platform-browser/animations/async';
import { provideRouter, withComponentInputBinding, withEnabledBlockingInitialNavigation } from '@angular/router';
import { CookieService } from 'ngx-cookie-service';
import { DynamicHooksModule, HookParserEntry } from 'ngx-dynamic-hooks';
import { AnalyticsService } from 'analytics';
import { AuthInterceptor, AuthService } from 'auth-data-access';
import { BRAND_CONFIG, BrandConfig } from 'brand';
import { FeatureFlagService } from 'feature-flag';
import { LanguageService } from 'language';
import { LinkComponent } from 'link';
import { CustomErrorHandler, httpErrorInterceptor } from 'monitoring';
import { ChannelService, CmsService, PRISMIC_ENDPOINT, PromotionService } from 'prismic';
import { EshopProductService, ProductService } from 'product';
import { SPRINKLR_APP_ID } from 'sprinklr-webwidget';
import { TooltipComponent } from 'tooltip';
import {
  TranslateService,
  TRANSLATION_DYNAMIC_VALUES,
  TRANSLATION_PRISMIC_TAG,
  TranslationDynamicValues,
} from 'translate';
import {
  BrowserService,
  calcEnvironmentUrls,
  ENVIRONMENT_URLS_CONFIG_TOKEN,
  EnvironmentUrlsConfig,
  FALLBACK_LANGUAGE,
} from 'utils';
import { environment } from '../environments/environment';
import { appRoutes } from './app.routes';

const initFeatureFlags = (featureFlagService: FeatureFlagService, analyticsService: AnalyticsService) => () =>
  featureFlagService.init(environment.featureFlagClientKey, data => analyticsService.addToDataLayer(data));

const calcUrlsConfig = (browser: BrowserService): EnvironmentUrlsConfig =>
  calcEnvironmentUrls(environment, browser.getOrigin());

const brandConfig: BrandConfig = {
  brand: environment.brand,
};

const componentParsers: Array<HookParserEntry> = [
  { component: TooltipComponent },
  {
    component: LinkComponent,
    inputsWhitelist: ['link'],
  },
];

const translationDynamicValues: TranslationDynamicValues = {
  BRAND: environment.brand === 'lebara' ? 'Lebara' : environment.brand,
};

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(appRoutes, withEnabledBlockingInitialNavigation(), withComponentInputBinding()),
    provideClientHydration(withEventReplay()),
    importProvidersFrom(
      BrowserModule,
      FormsModule,
      CommonModule,
      LanguageService,
      PromotionService,
      LanguageService,
      CmsService,
      ChannelService,
      AuthService,
      ReactiveFormsModule,
      MatDialogModule,
      DynamicHooksModule.forRoot({
        globalParsers: componentParsers,
      })
    ),
    CookieService,
    {
      provide: APP_INITIALIZER,
      useFactory: (cmsService: CmsService, translateService: TranslateService) => async () => {
        await cmsService.init();
        return translateService.initialize();
      },
      multi: true,
      deps: [CmsService, TranslateService],
    },
    { provide: APP_ID, useValue: 'website' },
    { provide: TRANSLATION_DYNAMIC_VALUES, useValue: translationDynamicValues },
    { provide: TRANSLATION_PRISMIC_TAG, useValue: 'website' },
    { provide: ErrorHandler, useClass: CustomErrorHandler },
    { provide: HTTP_INTERCEPTORS, useClass: AuthInterceptor, multi: true },
    { provide: MAT_DIALOG_DEFAULT_OPTIONS, useValue: { ...new MatDialogConfig(), panelClass: 'modal-container' } },
    { provide: ENVIRONMENT_URLS_CONFIG_TOKEN, useFactory: calcUrlsConfig, deps: [BrowserService] },
    { provide: BRAND_CONFIG, useValue: brandConfig },
    { provide: FALLBACK_LANGUAGE, useValue: environment.fallbackLang },
    { provide: PRISMIC_ENDPOINT, useValue: environment.prismicEndpoint },
    { provide: ProductService, useClass: EshopProductService },
    { provide: SPRINKLR_APP_ID, useValue: environment.sprinklerAppId },
    {
      provide: APP_INITIALIZER,
      useFactory: initFeatureFlags,
      multi: true,
      deps: [FeatureFlagService, AnalyticsService],
    },
    provideHttpClient(withInterceptorsFromDi(), withFetch(), withInterceptors([httpErrorInterceptor])),
    provideAnimationsAsync(),
  ],
};
